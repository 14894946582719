import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function HeaderProgressBarMobile(props: Props) {
  const {
    label,
    step,
    totalSteps,
    subtext,
    white,
    rightMargin,
    blue,
    stepTitle,
  } = props;

  const { t } = useTranslation();

  const firstWidth = useMemo(() => {
    const progress = (step / totalSteps) * 100;
    const width = (progress / 50) * 100;

    return width > 100 ? 100 : width;
  }, [step, totalSteps]);

  const secondWidth = useMemo(() => {
    const progress = (step / totalSteps) * 100 - 50;
    const width = (progress / 50) * 100;

    return width < 0 ? 0 : width > 100 ? 100 : width;
  }, [step, totalSteps]);

  return (
    <Stack className="absolute mt-[20px] left-0 right-0">
      <Stack
        className={`ml-[80px] items-center justify-center ${
          rightMargin ? 'mr-[80px]' : 'mr-[20px]'
        }`}
      >
        <Typography className="text-[#262626] text-[18px] font-semibold mb-[10px]">
          {label}
        </Typography>

        <Stack className="flex-row gap-x-[10px] self-stretch justify-center">
          <Box
            className="max-w-[172px] h-[6px] rounded-[36px] relative w-[40%]"
            sx={{ backgroundColor: white ? 'white' : '#f5f6f6' }}
          >
            <Box
              className={`absolute left-0 top-0 bottom-0 rounded-[36px] ${
                blue ? 'bg-mint' : 'bg-text'
              }`}
              sx={{ width: `${firstWidth}%` }}
            />
          </Box>

          <Box
            className="max-w-[172px] h-[6px] rounded-[36px] relative w-[40%]"
            sx={{ backgroundColor: white ? 'white' : '#f5f6f6' }}
          >
            <Box
              className={`absolute left-0 top-0 bottom-0 rounded-[36px] ${
                blue ? 'bg-mint' : 'bg-text'
              }`}
              sx={{ width: `${secondWidth}%` }}
            />
          </Box>
        </Stack>

        <Typography className="mt-[8px] text-[14px] font-medium text-[#26262680]">
          {subtext
            ? subtext
            : t('headerStep', {
                step,
                totalSteps,
              })}
        </Typography>
      </Stack>

      {stepTitle && (
        <Typography className="header-stepTitle header">{stepTitle}</Typography>
      )}
    </Stack>
  );
}

export interface Props {
  label: string;
  step: number;
  totalSteps: number;

  subtext?: string;
  white?: boolean;
  rightMargin?: boolean;
  blue?: boolean;
  stepTitle?: string;
}
