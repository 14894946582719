import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { AvatarsAPIResponse } from '../onboarding/types';
import {
  appEnvironment,
  mlClient,
  nodeClient,
  rubyClient,
} from '../common/environment';
import { accessTokenHeaders, mlAPIheaders } from '../redux/onboardingActions';
import { Country } from '../data/countries';
import i18next from '../i18n';

export const api = createApi({
  reducerPath: 'api',
  tagTypes: ['PhoneNumber'],
  baseQuery: fakeBaseQuery(),

  endpoints: (builder) => ({
    getAvatars: builder.query<
      AvatarsAPIResponse,
      { skin: string; emotion: string; gender: string }
    >({
      queryFn: async ({ skin, emotion, gender }) => {
        const { data } = await mlClient.get<AvatarsAPIResponse>('avatars', {
          headers: { apiKey: appEnvironment.mlAPIKey, skin, emotion, gender },
        });

        return { data };
      },
    }),

    emailAvailable: builder.mutation<{ available: boolean }, { email: string }>(
      {
        queryFn: async ({ email }) => {
          const { data } = await rubyClient.post<{ available: boolean }>(
            'users/email_available',
            { email }
          );

          return { data };
        },
      }
    ),

    getUserProfile: builder.query<
      {
        email: string;
        name: string;
        phone_number: string | null;
        kit_status: string | null;
      },
      void
    >({
      queryFn: async () => {
        const { data } = await nodeClient.get<{
          email: string;
          name: string;
          phone_number: string | null;
          kit_status: string | null;
        }>('users/profile', {
          headers: await mlAPIheaders(),
        });

        return { data };
      },
    }),

    getCountries: builder.query<Country[], boolean>({
      queryFn: async (filter) => {
        const { data } = await nodeClient.get<Country[]>(
          `countries?filter=${filter}&language=${
            i18next.resolvedLanguage ?? 'en'
          }`
        );

        return { data };
      },
    }),

    getPhoneNumber: builder.query<{ phone_number: string }, void>({
      queryFn: async () => {
        const { data } = await nodeClient.get<{ phone_number: string }>(
          'users/profile',
          {
            headers: await accessTokenHeaders({
              'Cache-Control': 'no-cache',
            }),
          }
        );

        return { data };
      },

      providesTags: () => [{ type: 'PhoneNumber' }],
    }),

    updatePhoneNumber: builder.mutation<unknown, { verificationId: number }>({
      queryFn: async ({ verificationId }) => {
        await nodeClient.post(
          'users/updatePhoneNumber',
          { verificationId },
          { headers: await accessTokenHeaders() }
        );

        return { data: {} };
      },

      invalidatesTags: [{ type: 'PhoneNumber' }],
    }),

    validateGoalInterests: builder.mutation<boolean, string>({
      queryFn: async (text) => {
        const { data } = await mlClient.post<{ valid: boolean }>(
          'goalInterestValidate',
          { text },
          { headers: await accessTokenHeaders() }
        );

        return { data: data.valid };
      },
    }),

    validateAllergens: builder.mutation<boolean, string>({
      queryFn: async (text) => {
        const { data } = await mlClient.post<{ valid: boolean }>(
          'AllergenValidate',
          { text },
          { headers: await accessTokenHeaders() }
        );

        return { data: data.valid };
      },
    }),

    validateDiets: builder.mutation<boolean, string>({
      queryFn: async (text) => {
        const { data } = await mlClient.post<{ valid: boolean }>(
          'DietValidate',
          { text },
          { headers: await accessTokenHeaders() }
        );

        return { data: data.valid };
      },
    }),
  }),
});

export const {
  useGetAvatarsQuery,
  useEmailAvailableMutation,
  useGetUserProfileQuery,
  useGetCountriesQuery,

  useGetPhoneNumberQuery,
  useUpdatePhoneNumberMutation,

  useValidateGoalInterestsMutation,
  useValidateAllergensMutation,
  useValidateDietsMutation,
} = api;
