import { Box, FilledTextFieldProps, TextField } from '@mui/material';
import { HTMLAttributes } from 'react';

export default function RoundBackgroundInput(props: RoundBackgroundInputProps) {
  const { endIcon, height, inputMode, ...rest } = props;

  return (
    <TextField
      variant="filled"
      slotProps={{
        input: {
          classes: {
            root: `bg-white rounded-text-input w-full px-4 hover:bg-white filled-input ${
              height ? `h-[${height}px]` : 'h-[56px]'
            }`,

            input: '!p-4 font-medium',
          },
          inputMode,
          disableUnderline: true,
          ...(endIcon
            ? { endAdornment: <Box component="img" src={endIcon} /> }
            : {}),
        },
      }}
      {...rest}
    />
  );
}

type RoundBackgroundInputProps = Omit<FilledTextFieldProps, 'variant'> & {
  endIcon?: string;
  height?: number;
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
};
