import { Alert, Box, Fade, Input, Stack, Typography } from '@mui/material';
import React, { useImperativeHandle } from 'react';
import {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useUpdatePhoneNumberMutation,
  useGetPhoneNumberQuery,
} from '../../api/api';
import { FADE_TIMEOUT, createStylesUtils } from '../../common/common';
import { formStyles } from '../../onboarding/styles';
import { ContinueRef } from '../../onboarding/types';
import { verifyCode, resendCode } from '../../redux/checkoutActions';
import { useAppSelector, useAppDispatch } from '../../redux/store';
import { usePhoneNumberGeneticsKits } from '../../common/hooks';
import { loggedInSliceActions, LoggedInStatus } from '../../redux/loggedIn';

const VerificationCodeForm = forwardRef<ContinueRef, unknown>((_props, ref) => {
  const [updatePhoneNumber] = useUpdatePhoneNumberMutation();
  const { data } = useGetPhoneNumberQuery();

  const { setLoggedInStatus } = usePhoneNumberGeneticsKits();

  useImperativeHandle(ref, () => ({
    continuePressed: async () => {
      const success = await dispatch(
        verifyCode({
          verificationId,
          code: `${first}${second}${third}${fourth}`,
        })
      ).unwrap();

      if (!success) {
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      } else {
        await updatePhoneNumber({ verificationId }).unwrap();
        setLoggedInStatus(LoggedInStatus.QR);
      }
    },
  }));

  const verificationId = useAppSelector(
    (state) => state.checkout.personalData.verificationId
  );
  const dispatch = useAppDispatch();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const [first, setFirst] = useState('_');
  const [second, setSecond] = useState('_');
  const [third, setThird] = useState('_');
  const [fourth, setFourth] = useState('_');

  const firstRef = useRef<HTMLInputElement>(null);
  const secondRef = useRef<HTMLInputElement>(null);
  const thirdRef = useRef<HTMLInputElement>(null);
  const fourthRef = useRef<HTMLInputElement>(null);

  const notCompleted = useMemo(() => {
    const values = [first, second, third, fourth];
    const notCompleted =
      values.findIndex((item) => item === '_' || item === '') >= 0;

    return notCompleted;
  }, [first, fourth, second, third]);

  useEffect(() => {
    dispatch(loggedInSliceActions.setButtonDisabled(notCompleted));
  }, [dispatch, notCompleted]);

  const onFocus = useCallback(
    (value: string, setValue: (value: string) => void) => {
      return () => {
        if (value === '_') {
          setValue('');
        }
      };
    },
    []
  );

  const onBlur = useCallback(
    (value: string, setValue: (value: string) => void) => {
      return () => {
        if (value === '') {
          setValue('_');
        }
      };
    },
    []
  );

  const onPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const value = event.clipboardData.getData('text');
      if (value.length === 4 && !isNaN(Number(value))) {
        setFirst(value[0]);
        setSecond(value[1]);
        setThird(value[2]);
        setFourth(value[3]);
      }
    },
    []
  );

  const onChange = useCallback(
    (setValue: (value: string) => void, ref?: RefObject<HTMLInputElement>) => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (event.target.value === '') {
          setValue('');
          return;
        }
        if (isNaN(Number(event.target.value))) {
          setValue('');
        } else {
          setValue(event.target.value);
          setTimeout(() => {
            if (ref && ref.current) {
              ref.current.focus();
            }
          }, 50);
        }
      };
    },
    []
  );

  const onKeyDown = useCallback((prevRef?: RefObject<HTMLInputElement>) => {
    return (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.key === 'Backspace' && prevRef && prevRef.current) {
        setTimeout(() => {
          prevRef!.current!.focus();
        }, 50);
      }
    };
  }, []);

  const onResendCodePressed = useCallback(async () => {
    await dispatch(resendCode({ verificationId })).unwrap();
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 3000);
  }, [dispatch, verificationId]);

  return (
    <Fade in={true} timeout={FADE_TIMEOUT} appear={true}>
      <Box sx={formStyles.container}>
        <Stack direction="row" justifyContent="center">
          <Input
            inputProps={{ maxLength: 1, inputMode: 'numeric' }}
            sx={styles.input}
            onFocus={onFocus(first, setFirst)}
            onBlur={onBlur(first, setFirst)}
            onChange={onChange(setFirst, secondRef)}
            value={first}
            inputRef={firstRef}
            onPaste={onPaste}
          />
          <Input
            inputProps={{
              maxLength: 1,
              inputMode: 'numeric',
            }}
            sx={styles.input}
            onFocus={onFocus(second, setSecond)}
            onBlur={onBlur(second, setSecond)}
            onChange={onChange(setSecond, thirdRef)}
            value={second}
            inputRef={secondRef}
            onKeyDown={onKeyDown(firstRef)}
            onPaste={onPaste}
          />
          <Input
            inputProps={{ maxLength: 1, inputMode: 'numeric' }}
            sx={styles.input}
            onFocus={onFocus(third, setThird)}
            onBlur={onBlur(third, setThird)}
            onChange={onChange(setThird, fourthRef)}
            value={third}
            inputRef={thirdRef}
            onKeyDown={onKeyDown(secondRef)}
            onPaste={onPaste}
          />
          <Input
            inputProps={{ maxLength: 1, inputMode: 'numeric' }}
            sx={styles.input}
            onFocus={onFocus(fourth, setFourth)}
            onBlur={onBlur(fourth, setFourth)}
            onChange={onChange(setFourth)}
            value={fourth}
            inputRef={fourthRef}
            onKeyDown={onKeyDown(thirdRef)}
            onPaste={onPaste}
          />
        </Stack>

        <Typography sx={styles.resendCode} onClick={onResendCodePressed}>
          Resend code
        </Typography>

        <Typography>{data?.phone_number}</Typography>

        <Box
          sx={{
            marginTop: '16px',
            position: 'relative',
            width: '300px',
            '& .MuiAlert-root': {
              position: 'absolute',
              left: '50%',
              webkitTransform: 'translateX(-50%)',
              transform: 'translateX(-50%)',
            },
          }}
        >
          <Fade in={showSuccess}>
            <Alert severity="success">Code resent</Alert>
          </Fade>

          <Fade in={showError}>
            <Alert severity="error" className="secondAlert">
              Invalid code
            </Alert>
          </Fade>
        </Box>
      </Box>
    </Fade>
  );
});

const styles = createStylesUtils({
  input: {
    '&.MuiInput-root': {
      '::after, ::before, :hover::before': { border: 'none' },
      padding: '16px',
      background: 'white',
      borderRadius: '23px',
      marginRight: '16px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    '& .MuiInput-input': {
      fontSize: '36px',
      lineHeight: '36px',
      padding: '0px',
      textAlign: 'center',
      width: '30px',
    },
  },

  resendCode: {
    marginTop: '20px',
    fontSize: '13px',
    lineHeight: '16px',
    textDecorationLine: 'underline',
    fontWeight: '500',
    color: '#A09FA3',
    ':hover': {
      cursor: 'pointer',
    },
  },
});

export default VerificationCodeForm;
