import {
  Box,
  GlobalStyles,
  Stack,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { theme } from '../common/theme';
import { useTranslation } from 'react-i18next';
import HeaderProgressBarMobile from '../components_v2/header/HeaderPogressMobile';
import { LoadingButton } from '@mui/lab';
import { images } from '../assets/images';
import { useRef } from 'react';
import { ContinueRef } from '../onboarding/types';
import VerificationCodeForm from '../components_v2/forms/VerificationCodeForm';
import { usePhoneNumberGeneticsKits } from '../common/hooks';
import { LoggedInStatus } from '../redux/loggedIn';
import PhoneForm from '../components_v2/forms/PhoneForm';
import QRForm from '../components_v2/logged_in/QRForm';
import { useAppSelector } from '../redux/store';

export default function LoggedInV2Mobile() {
  const { t } = useTranslation();

  const { loggedInStatus } = usePhoneNumberGeneticsKits();

  const buttonDisabled = useAppSelector(
    (state) => state.loggedIn.buttonDisabled
  );

  const ref = useRef<ContinueRef>(null);

  if (loggedInStatus === LoggedInStatus.Unknown) {
    return null;
  }

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{ body: { background: '#F5F6F6', padding: 0, margin: 0 } }}
        />

        <Stack className="root-container">
          {loggedInStatus !== LoggedInStatus.QR && (
            <HeaderProgressBarMobile
              label={t('signUp')}
              step={2}
              totalSteps={2}
              subtext={t('completedExclamationMark')}
              blue={true}
              rightMargin={true}
              stepTitle={t('yourPhoneNumber')}
            />
          )}

          {loggedInStatus !== LoggedInStatus.QR && (
            <Stack
              alignSelf={'stretch'}
              flex={1}
              sx={{ paddingTop: '120px', margin: '0px 20px', gap: '30px' }}
              justifyContent={'center'}
            >
              <Stack flex={1} justifyContent={'center'}>
                {loggedInStatus === LoggedInStatus.Phone && (
                  <PhoneForm ref={ref} />
                )}

                {loggedInStatus === LoggedInStatus.VerificationCode && (
                  <VerificationCodeForm ref={ref} />
                )}
              </Stack>

              <LoadingButton
                variant="contained"
                endIcon={<Box component="img" src={images.v2.button.tick} />}
                className={`button large bg-text`}
                onClick={() => {
                  ref?.current?.continuePressed();
                }}
                disabled={buttonDisabled}
                sx={{ marginBottom: '20px' }}
              >
                {t('continue')}
              </LoadingButton>
            </Stack>
          )}

          {loggedInStatus === LoggedInStatus.QR && (
            <Stack className={`flex-1 self-stretch bg-[#F6F5F5]`}>
              <Stack
                className="p-[24px] mt-[20px] h-0 grow z-50 overflow-y-scroll"
                justifyContent="flex-start"
                alignItems={'flex-start'}
                gap="8px"
              >
                <QRForm />
              </Stack>
            </Stack>
          )}
        </Stack>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
