import { ThemeProvider } from '@emotion/react';
import {
  StyledEngineProvider,
  GlobalStyles,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useRef } from 'react';
import { images } from './assets/images';
import { theme } from './common/theme';

import './logged-in.css';
import SYDLeftPanelContainer from './components_v2/SYDLeftPanelContainer';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppTransitions } from './ui';
import HeaderProgressBar from './components_v2/header/HeaderPogressBar';
import { LoadingButton } from '@mui/lab';
import { ContinueRef } from './onboarding/types';
import VerificationCodeForm from './components_v2/forms/VerificationCodeForm';
import { LoggedInStatus } from './redux/loggedIn';
import { usePhoneNumberGeneticsKits } from './common/hooks';
import PhoneForm from './components_v2/forms/PhoneForm';
import QRForm from './components_v2/logged_in/QRForm';
import { useAppSelector } from './redux/store';

export default function LoggedInV2() {
  const { t } = useTranslation();

  const { loggedInStatus } = usePhoneNumberGeneticsKits();

  const buttonDisabled = useAppSelector(
    (state) => state.loggedIn.buttonDisabled
  );

  const leftPanelImageRef = useRef<HTMLImageElement>(null);

  const ref = useRef<ContinueRef>(null);

  const { leftPanelTranslationX } = useAppTransitions(leftPanelImageRef, false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(
      searchParams.get('lng') !== null ? `lng=${searchParams.get('lng')}` : ''
    );
  }, [searchParams, setSearchParams]);

  const showingPhoneNumber = useMemo(() => {
    return [LoggedInStatus.Phone, LoggedInStatus.VerificationCode].includes(
      loggedInStatus
    );
  }, [loggedInStatus]);

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{ body: { background: '#F5F6F6', padding: 0, margin: 0 } }}
        />

        <Stack className={`root-container`}>
          <HeaderProgressBar
            label={t('signUp')}
            step={2}
            totalSteps={2}
            subtext={t('completedExclamationMark')}
            blue={true}
            stepTitle={showingPhoneNumber ? t('yourPhoneNumber') : undefined}
            stepTitleExtraTopMargin={showingPhoneNumber}
          />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              component="img"
              src={images.header.sydLogo}
              className="logo w-76 h-76 m-10"
            />
          </Stack>

          <Stack
            direction="row"
            className={`w-full flex-1 h-0 relative transition-opacity ${
              leftPanelTranslationX !== 0 ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <Stack direction="row" className="w-full" alignSelf="center">
              <Stack
                direction="row"
                flexGrow={1}
                className="w-0"
                alignSelf="center"
                justifyContent="center"
                sx={{ transform: `translateX(${leftPanelTranslationX}px)` }}
              >
                <SYDLeftPanelContainer imageRef={leftPanelImageRef} />
              </Stack>

              <Stack
                direction="row"
                justifyContent="center"
                className="absolute left-0 top-0 right-0 bottom-0"
              >
                <Stack
                  direction="row"
                  flexGrow={1}
                  justifyContent="center"
                  alignItems="center"
                  alignSelf="center"
                  className="w-0 mt-[80px]"
                >
                  {loggedInStatus === LoggedInStatus.QR && <QRForm />}

                  {loggedInStatus === LoggedInStatus.Phone && (
                    <PhoneForm ref={ref} />
                  )}

                  {loggedInStatus === LoggedInStatus.VerificationCode && (
                    <VerificationCodeForm ref={ref} />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          {showingPhoneNumber ? (
            <Stack
              className="macbook13:my-0 macbook13:mb-[20px] my-10"
              alignItems={'center'}
            >
              <Typography component={'span'} className="signUp-note dark">
                <Typography component={'span'} className="signUp-note">
                  98%
                </Typography>{' '}
                {t('percentOfMembers')}
              </Typography>

              <LoadingButton
                variant="contained"
                disabled={buttonDisabled}
                endIcon={<Box component="img" src={images.v2.button.tick} />}
                className={`button large bg-text`}
                onClick={() => {
                  if (ref.current) {
                    ref.current.continuePressed();
                  }
                }}
              >
                {t('continue')}
              </LoadingButton>
            </Stack>
          ) : (
            <Box className="h-[48px] my-10" />
          )}
        </Stack>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
