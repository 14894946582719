import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum LoggedInStatus {
  Phone = 'phone',
  VerificationCode = 'verificationCode',
  QR = 'qr',
  Unknown = 'unknown',
}

interface State {
  status: LoggedInStatus;
  buttonDisabled: boolean;
}

const initialState: State = {
  status: LoggedInStatus.Unknown,
  buttonDisabled: true,
};

export const loggedInSlice = createSlice({
  name: 'loggedIn',
  initialState,
  reducers: {
    setLoggedInStatus(state, action: PayloadAction<LoggedInStatus>) {
      state.status = action.payload;
    },

    setButtonDisabled(state, action: PayloadAction<boolean>) {
      state.buttonDisabled = action.payload;
    },
  },
});

export const loggedInSliceActions = loggedInSlice.actions;

export default loggedInSlice.reducer;
