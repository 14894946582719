import { useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Step } from '../redux/checkout';
import { useGetUserProfileQuery } from '../api/api';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { loggedInSliceActions } from '../redux/loggedIn';

export const useMobile = () => {
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  return mobile;
};

export const useSmallDevice = () => {
  const smallDevice = useMediaQuery('(max-height:700px)');

  return smallDevice;
};

export const useParamStep = (acceptedSteps?: Step[]) => {
  const { step: paramStep } = useParams<{ step: string }>();

  const isAcceptedStep = useMemo(() => {
    if (paramStep) {
      const step = parseInt(paramStep);
      if (
        acceptedSteps === undefined ||
        acceptedSteps.length === 0 ||
        acceptedSteps.includes(step)
      ) {
        return true;
      }
    }

    return false;
  }, [acceptedSteps, paramStep]);

  const step = useMemo<Step>(() => {
    if (paramStep) {
      const step = parseInt(paramStep);
      if (
        acceptedSteps === undefined ||
        acceptedSteps.length === 0 ||
        acceptedSteps.includes(step)
      ) {
        return step;
      } else {
        return acceptedSteps && acceptedSteps.length > 0
          ? acceptedSteps[0]
          : Step.CreateAccount;
      }
    }

    return acceptedSteps && acceptedSteps.length > 0
      ? acceptedSteps[0]
      : Step.CreateAccount;
  }, [acceptedSteps, paramStep]);

  return { step, isAcceptedStep };
};

enum LoggedInStatus {
  Phone = 'phone',
  VerificationCode = 'verificationCode',
  QR = 'qr',
  Unknown = 'unknown',
}

export const usePhoneNumberGeneticsKits = () => {
  const { data } = useGetUserProfileQuery();

  const dispatch = useAppDispatch();

  const loggedInStatus = useAppSelector((state) => state.loggedIn.status);

  const setLoggedInStatus = useCallback(
    (status: LoggedInStatus) => {
      dispatch(loggedInSliceActions.setLoggedInStatus(status));
    },
    [dispatch]
  );

  useEffect(() => {
    if (loggedInStatus === LoggedInStatus.Unknown && data) {
      if (!data.phone_number && data.kit_status === 'ordered') {
        setLoggedInStatus(LoggedInStatus.Phone);
      } else {
        setLoggedInStatus(LoggedInStatus.QR);
      }
    }
  }, [data, loggedInStatus, setLoggedInStatus]);

  return { loggedInStatus, setLoggedInStatus };
};
