import {
  Stack,
  Typography,
  Select,
  MenuItem,
  Fade,
  FormHelperText,
  Box,
} from '@mui/material';
import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';
import {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { FADE_TIMEOUT } from '../../common/common';
import { appEnvironment, autoCompleteData } from '../../common/environment';
import { ContinueRef } from '../../onboarding/types';
import { checkoutActions } from '../../redux/checkout';
import { createVerification } from '../../redux/checkoutActions';
import { useAppDispatch } from '../../redux/store';
import RoundBackgroundInput from '../input/RoundBackgroundInput';
import { useGetCountriesQuery } from '../../api/api';
import { useMobile, usePhoneNumberGeneticsKits } from '../../common/hooks';
import { loggedInSliceActions, LoggedInStatus } from '../../redux/loggedIn';
import i18next from 'i18next';
import { I8NextLanguageCode, i8nextLanguageToCountry } from '../../i18n';

const PhoneForm = forwardRef<ContinueRef, unknown>((_props, ref) => {
  const { data: countries } = useGetCountriesQuery(false);

  const { setLoggedInStatus } = usePhoneNumberGeneticsKits();

  useImperativeHandle(ref, () => ({
    continuePressed: async () => {
      onPhoneNumberBlur();

      const parsedNumber = parsePhoneNumber(
        phoneNumber,
        selectedCountry as CountryCode
      ).format('E.164');

      if (phoneNumberError.length === 0) {
        try {
          await dispatch(
            createVerification({ phoneNumber: parsedNumber })
          ).unwrap();

          dispatch(
            checkoutActions.setPersonalData({ phoneNumber: parsedNumber })
          );
          setLoggedInStatus(LoggedInStatus.VerificationCode);
        } catch (e) {
          setPhoneNumberError(e as string);
        }
      }

      return {};
    },
  }));

  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(
    i8nextLanguageToCountry[i18next.language as I8NextLanguageCode] || 'GB'
  );

  const [phoneNumber, setPhoneNumber] = useState(
    appEnvironment.autocomplete ? autoCompleteData.phoneNumber : ''
  );
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      loggedInSliceActions.setButtonDisabled(
        !isValidPhoneNumber(phoneNumber, selectedCountry as CountryCode)
      )
    );
  }, [dispatch, phoneNumber, phoneNumberError.length, selectedCountry]);

  const onPhoneNumberChange = useMemo(() => {
    return (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setPhoneNumberError('');
      setPhoneNumber(event.target.value);
    };
  }, []);

  const onPhoneNumberBlur = useCallback(() => {
    if (!isValidPhoneNumber(phoneNumber, selectedCountry)) {
      setPhoneNumberError('Invalid phone number');
    }
  }, [phoneNumber, selectedCountry]);

  const sortedCountries = useMemo(() => {
    if (!countries) {
      return [];
    }

    const clonedCountries = [...countries];
    clonedCountries.sort((a, b) => a.label.localeCompare(b.label));
    return clonedCountries;
  }, [countries]);

  const mobile = useMobile();

  if (!countries) {
    return <Box sx={{ flex: 1 }} />;
  }

  return (
    <Fade in={true} timeout={FADE_TIMEOUT} appear={true}>
      <Stack
        direction="row"
        alignItems={mobile ? 'center' : 'flex-end'}
        className="w-full gap-[5px] max-w-[700px]"
        sx={{ flex: 1 }}
      >
        <Select
          variant="outlined"
          className="autocomplete no-full-width"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value as CountryCode)}
          MenuProps={{
            sx: { height: '400px' },
            variant: 'menu',
          }}
          renderValue={(value) => {
            const country = countries.find((c) => c.code === value);
            return (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                className="px-4 gap-[5px]"
              >
                <img
                  src={country?.flag}
                  className="w-[24px] h-[24px] rounded-[12px]"
                />
                <Typography className="font-medium text-[14px] leading-[18px]">
                  +{country?.phone}
                </Typography>
              </Stack>
            );
          }}
        >
          {sortedCountries.map((country) => (
            <MenuItem value={country.code} key={country.code}>
              <Stack
                direction="row"
                alignItems="center"
                className="px-4 gap-[10px]"
              >
                <img src={country.flag} className="rounded-text-input" />
                <Typography className="font-bold min-w-[40px]">
                  +{country.phone}
                </Typography>

                <Typography className="font-medium">{country.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>

        <Stack className="relative" flex={1}>
          <RoundBackgroundInput
            value={phoneNumber}
            placeholder="Phone number"
            onChange={onPhoneNumberChange}
            onBlur={onPhoneNumberBlur}
            error={phoneNumberError.length > 0}
            height={56}
          />

          <FormHelperText
            error={true}
            className="absolute left-[10px] bottom-[-5px] translate-y-[100%]"
          >
            {phoneNumberError}
          </FormHelperText>
        </Stack>
      </Stack>
    </Fade>
  );
});

export default PhoneForm;
