import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/json/en.json';
import ja from './translations/json/ja.json';
import ar from './translations/json/ar.json';

import cs from './translations/json/cs.json';
import da from './translations/json/da.json';
import de from './translations/json/de.json';
import el from './translations/json/el.json';
import es from './translations/json/es.json';
import fi from './translations/json/fi.json';
import fr from './translations/json/fr.json';
import ko from './translations/json/ko.json';
import pt from './translations/json/pt.json';

import it from './translations/json/it.json';
import id from './translations/json/id.json';
import nl from './translations/json/nl.json';
import tr from './translations/json/tr.json';
import tl from './translations/json/tl.json';
import pl from './translations/json/pl.json';
import sv from './translations/json/sv.json';

import bg from './translations/json/bg.json';
import ro from './translations/json/ro.json';
import hr from './translations/json/hr.json';
import ms from './translations/json/ms.json';
import hi from './translations/json/hi.json';
import sk from './translations/json/sk.json';
import ta from './translations/json/ta.json';
import uk from './translations/json/uk.json';
import ru from './translations/json/ru.json';
import zh from './translations/json/zh.json';

import detector from 'i18next-browser-languagedetector';
import { CountryCode } from 'libphonenumber-js';

//array of all languages in format {code: 'en', translation: en}
const languages = [
  { code: 'en', translation: en },
  { code: 'ja', translation: ja },
  { code: 'ar', translation: ar },
  { code: 'cs', translation: cs },
  { code: 'da', translation: da },
  { code: 'de', translation: de },
  { code: 'el', translation: el },
  { code: 'es', translation: es },
  { code: 'fi', translation: fi },
  { code: 'fr', translation: fr },
  { code: 'ko', translation: ko },
  { code: 'pt', translation: pt },
  { code: 'it', translation: it },
  { code: 'id', translation: id },
  { code: 'nl', translation: nl },
  { code: 'tr', translation: tr },
  { code: 'tl', translation: tl },
  { code: 'pl', translation: pl },
  { code: 'sv', translation: sv },
  { code: 'bg', translation: bg },
  { code: 'ro', translation: ro },
  { code: 'hr', translation: hr },
  { code: 'ms', translation: ms },
  { code: 'hi', translation: hi },
  { code: 'sk', translation: sk },
  { code: 'ta', translation: ta },
  { code: 'uk', translation: uk },
  { code: 'ru', translation: ru },
  { code: 'zh', translation: zh },
] as const;

type Resources = { [key: string]: { translation: typeof en } };

export type I8NextLanguageCode = typeof languages[number]['code'];

export const i8nextLanguageToCountry: Record<I8NextLanguageCode, CountryCode> =
  {
    en: 'GB', // English → UK
    ja: 'JP', // Japanese → Japan
    ar: 'SA', // Arabic → Saudi Arabia
    cs: 'CZ', // Czech → Czech Republic
    da: 'DK', // Danish → Denmark
    de: 'DE', // German → Germany
    el: 'GR', // Greek → Greece
    es: 'ES', // Spanish → Spain
    fi: 'FI', // Finnish → Finland
    fr: 'FR', // French → France
    ko: 'KR', // Korean → South Korea
    pt: 'PT', // Portuguese → Portugal
    it: 'IT', // Italian → Italy
    id: 'ID', // Indonesian → Indonesia
    nl: 'NL', // Dutch → Netherlands
    tr: 'TR', // Turkish → Turkey
    tl: 'PH', // Tagalog → Philippines
    pl: 'PL', // Polish → Poland
    sv: 'SE', // Swedish → Sweden
    bg: 'BG', // Bulgarian → Bulgaria
    ro: 'RO', // Romanian → Romania
    hr: 'HR', // Croatian → Croatia
    ms: 'MY', // Malay → Malaysia
    hi: 'IN', // Hindi → India
    sk: 'SK', // Slovak → Slovakia
    ta: 'LK', // Tamil → Sri Lanka
    uk: 'UA', // Ukrainian → Ukraine
    ru: 'RU', // Russian → Russia
    zh: 'CN', // Chinese → China
  };

const resources = languages.reduce<Resources>((acc, { code, translation }) => {
  acc[code] = { translation };
  return acc;
}, {});

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',

    resources,
  });

export default i18next;
